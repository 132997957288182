
<mat-nav-list>
  <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
  </a>
  <a mat-list-item routerLink="/hoftrac" (click)="onSidenavClose()">
    <mat-icon>turned_in</mat-icon> <span class="nav-caption">Hoftrac</span>
  </a>
  <a mat-list-item routerLink="/radlader" (click)="onSidenavClose()">
    <mat-icon>turned_in</mat-icon><span class="nav-caption">Radlader</span>
  </a>
  <a mat-list-item routerLink="/teleskoplader" (click)="onSidenavClose()">
    <mat-icon>turned_in</mat-icon><span class="nav-caption">Teleskoplader</span>
  </a>
  <a mat-list-item routerLink="/teleskopradlader" (click)="onSidenavClose()">
    <mat-icon>turned_in</mat-icon><span class="nav-caption">Teleskopradlader</span>
  </a>
  <a mat-list-item routerLink="/extras" (click)="onSidenavClose()">
    <mat-icon>build</mat-icon><span class="nav-caption">Extras</span>
  </a>
  <a mat-list-item routerLink="/kontakt" (click)="onSidenavClose()">
    <mat-icon>assignment_ind</mat-icon><span class="nav-caption">Kontakt</span>
  </a>
 </mat-nav-list>

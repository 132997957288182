<!--<header>
  <div class="container">
    <a routerLink="/" class="logo">Aulatec</a>
    <nav>
      <ul>
        <li><a href="#" routerLink="/">Home</a></li>
        <li><a href="#" routerLink="/hoftrac">Hoftrac</a></li>
        <li><a href="#" routerLink="/radlader">Radlader</a></li>
        <li><a href="#" routerLink="/teleskoplader">Telekoplader</a></li>
        <li><a href="#" routerLink="/teleskopradlader">Teleskopradlader</a></li>
        <li><a href="#" routerLink="/extras">Extras</a></li>
        <li><a href="#" routerLink="/kontakt">Kontakt</a></li>
      </ul>
    </nav>
  </div>
</header> -->
<!-------
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <style>
    body {
      margin: 0;
      font-family: Arial, Helvetica, sans-serif;
    }

    .topnav {
      overflow: hidden;
      background-color: #333;
    }

    .topnav a {
      float: left;
      display: block;
      color: #f2f2f2;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      font-size: 17px;
    }

    .topnav a:hover {
      background-color: #ddd;
      color: black;
    }

    .topnav a.active {
      background-color: #4CAF50;
      color: white;
    }

    .topnav .icon {
      display: none;
    }

    @media screen and (max-width: 600px) {
      .topnav a:not(:first-child) {display: none;}
      .topnav a.icon {
        float: right;
        display: block;
      }
    }

    @media screen and (max-width: 600px) {
      .topnav.responsive {position: relative;}
      .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
      }
      .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
      }
    }
  </style>
</head>
<body>

<div class="topnav" id="myTopnav">
  <a href="/" class="active">Home</a>
  <a href="/hoftrac">News</a>
  <a href="/radlader">Contact</a>
  <a href="/kontakt">About</a>
  <a href="javascript:void(0);" class="icon" onclick="myFunction()">
    <i class="fa fa-bars"></i>
  </a>
</div>


<script type="text/javascript">
  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

</script>


-->


<!-------
<div class="card" >
  <ul>
    <li *ngFor="let item of menuItems"> (1)
      {{ item }}
    </li>
  </ul>
</div>

</body>
 -->



<!-- -->

<mat-sidenav #sidenav role="navigation">
  <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
</mat-sidenav>




  <mat-sidenav-container>
    <!--<mat-sidenav #sidenav role="navigation">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>-->
    <mat-sidenav-content>
      <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
      <main>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>




<!-- video -->

<div class="container">


  <!-- dynamic Background video -->
    <video autoplay muted loop id="myVideo">
      <source src="../assets/img/img.component.blackDots.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
  <!-- ende -->

  <router-outlet></router-outlet>

</div>



<!-- -->


import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-hoftrac',
  templateUrl: './hoftrac.component.html',
  styleUrls: ['./hoftrac.component.scss']
})
export class HoftracComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

 // hoftracSlider = {items: 1, dots: true, nav: true}



}

<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
    <h1>Teleskopradlader </h1>
  </div>
</div>
<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
    <i>
      <h2> Für alle die hoch hinaus wollen <br></h2></i><br>
    Immer wenn es darauf ankommt mehr Hubhöhe zu erzielen, eignet sich ein Teleskopradlader besonders gut. Denn neben hoher Funktionalität, großem Arbeitskomfort und hervorragenden Sicherheitsstandards besitzen diese Maschinen im Vergleich zum normalen Radlader mit Ladeschwinge einen Teleskoparm und haben dadurch eine deutlich gesteigerte Hubhöhe und Reichweite. Dabei treffen die Grundtugenden wie Wendigkeit, Standsicherheit und Hubkraft, die einen knickgelenkten Radlader auszeichnen, auch auf einen Teleskopradlader zu – dies macht sie so erfolgreich.
  </div>
</div>


<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <h2 class="card-title">2080LPT</h2>
    <img class="card-img-top" src="../../assets/img/teleskopradlader/img.component.teleskopradlader1.png" alt="Teleskopradlader" >
    <img class="card-img-top" src="../../assets/img/teleskopradlader/img.component.teleskopradlader2.png" alt="Teleskopradlader">
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">2080LPT</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">(Max.) Motorleistung</th>
          <td>45 (61) kW</td>
        </tr>
        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>4400 kg</td>
        </tr>
        <tr>
          <th scope="row">Kipplast mit Schaufel/Maschine gerade</th>
          <td>2.437 - 2.556 kg </td>
        </tr>
        <tr>
          <th scope="row">Gesamtbreite</th>
          <td>1.415 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/teleskopradlader/model/2080lpt/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/teleskopradlader/model/2080lpt/">mehr Informationen zum 2080LPT</a>
    </div>
  </div>
</div>

<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <h2 class="card-title">2080T</h2>
    <img class="card-img-top" src="../../assets/img/teleskopradlader/img.component.teleskopradlader3.png" alt="Teleskopradlader" >
    <img class="card-img-top" src="../../assets/img/teleskopradlader/img.component.teleskopradlader4.png" alt="Teleskopradlader" >
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">2080T</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">(Max.) Motorleistung</th>
          <td>45 (61) kW</td>
        </tr>
        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>4.500 / 4600 kg</td>
        </tr>
        <tr>
          <th scope="row">Kipplast mit Schaufel/Maschine gerade</th>
          <td>ca. 2.632 kg </td>
        </tr>
        <tr>
          <th scope="row">Gesamtbreite</th>
          <td>1.410 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/teleskopradlader/model/2080t/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/teleskopradlader/model/2080t/">mehr Informationen zum 2080T</a>
    </div>
  </div>
</div>


<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <h2 class="card-title">3080LPT</h2>
    <img class="card-img-top" src="../../assets/img/teleskopradlader/img.component.teleskopradlader5.png" alt="Teleskopradlader" >
    <img class="card-img-top" src="../../assets/img/teleskopradlader/img.component.teleskopradlader6.png" alt="Teleskopradlader" >
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">3080LPT</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">(Max.) Motorleistung</th>
          <td>45 (61) kW</td>
        </tr>
        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>5.000 kg</td>
        </tr>
        <tr>
          <th scope="row">Kipplast mit Schaufel/Maschine gerade</th>
          <td>2.435 / 1295 kg </td>
        </tr>
        <tr>
          <th scope="row">Gesamtbreite</th>
          <td>1.530 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/teleskopradlader/model/3080lpt/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/teleskopradlader/model/3080lpt/">mehr Informationen zum 3080LPT</a>
    </div>
  </div>
</div>

<div class = "mb-4"></div>

<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
    <h1>Extras und Zubehör</h1>
  </div>
</div>
<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <img class="card-img-top" src="../../assets/img/img.component.extras.png" alt="Extras">
    <div class="card-body">
      <h5 class="card-title">Alle Anbauwerkzeuge im Detail</h5>
      <p class="card-text">
        Einen genauen Überblick über alle bei Weidemann ab Werk verfügbaren Anbauwerkzeuge und die Zuordnung zur passenden Maschine erhalten Sie hier.
      </p>
      <a href="https://www.weidemann.de/de/zubehoer/anbauwerkzeuge/alle-anbauwerkzeuge-im-detail/" class="card-link">Anbauwerkzeuge</a>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top" src="../../assets/img/extras/img.component.werkzeugwechsel.jpg" alt="Werkzeugwechsel">
    <div class="card-body">
      <h5 class="card-title">Werkzeugwechsel</h5>
      <p class="card-text">
        Dank des hydraulischen Schnellwechselsystems lassen sich Anbauwerkzeuge bequem vom Fahrersitz aus austauschen. So ist Ihre Maschine sofort wieder einsatzbereit. Das steigert die Produktivität und erhöht die Wirtschaftlichkeit.
      </p>
      <a href="https://www.weidemann.de/de/zubehoer/anbauwerkzeuge/werkzeugwechsel/" class="card-link">Zum Werkzeugwechsel</a>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top" src="../../assets/img/extras/img.component.aufnahme.jpg" alt="Aufnahmen">
    <div class="card-body">
      <h5 class="card-title">Aufnahmen</h5>
      <p class="card-text">
        Weidemann Maschinen werden standardmäßig mit einer eigenen Weidemann Aufnahme ausgestattet. Andere Aufnahmen sind optional erhältlich. Hier bekommen Sie einen Überblick, welche Weidemann Maschine mit welcher Aufnahme ausgestattet ist.      </p>
      <a href="https://www.weidemann.de/de/zubehoer/anbauwerkzeuge/aufnahmen/" class="card-link">Zu den Werkzeugaufnahmen</a>
    </div>
  </div>
</div>

<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <img class="card-img-top" src="../../assets/img/extras/img.component.optionen.jpg" alt="Extras">
    <div class="card-body">
      <h5 class="card-title">Optionen</h5>
      <p class="card-text">
        Ihre Weidemann Maschine kann durch verschiedene Optionen für einen professionellen Werkzeugeinsatz aufgerüstet werden.
        Ergonomisches Arbeiten durch professionellen Werkzeug-Einsatz.
      </p>
      <a href="https://www.weidemann.de/de/zubehoer/anbauwerkzeuge/optionen/" class="card-link">Optionen</a>
    </div>
  </div>

  <div class="card">
    <img class="card-img-top" src="../../assets/img/extras/img.component.reifen.png" alt="Reifen">
    <div class="card-body">
      <h5 class="card-title">Bereifung</h5>
      <p class="card-text">

        Die passende Bereifung für Ihren Einsatzbereich
      </p>
      <a href="https://www.weidemann.de/de/zubehoer/bereifung/" class="card-link">Zur Bereifung</a>
    </div>
  </div>
</div>
<div class = "mb-4"></div>

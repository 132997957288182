<div class = "mb-4"></div>

<h1 style="color: white">Willkommen bei Aulatec</h1>
  <!-- dynamic content 2
  <div class="section">

    <h1>Welcome To Paradise</h1>

    <div class="video-container">
      <div class="color-overlay"></div>
      <video autoplay loop>
        <source src="../../assets/img/img.component.redDynamicBackground.mp4" type="video/mp4">
      </video>
    </div>
  </div>

  <style>
    .section {
      position: relative;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .section h1 {
      text-align: center;
      font-size: 6rem;
      font-family: "Cookie";
      padding: 20px;
      margin: 15px;
      z-index: 1;
      opacity: 0.7;
      color: white;
    }

    .video-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

/*    .color-overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: lightgray;
      width: 100%;
      height: 100vh;
      opacity: 0.5;
    }
    */

  </style>-->
  <!-- dynamic ende -->


<!-- <div class="play-container" >
    <p>You've clicked <span (click)="countClick()">this</span> {{ clickCounter }} times.</p>
  </div> -->
<div class="card-deck">
  <div class="card">
    <div class="card-body">
      <h2 class="card-title">Ihrem Weidemannvertriebspartner.</h2><br>
      <p class="card-text"> Kompetente und Praxisnahe Beratung für alle Weidemannmaschinen.<br>
    Weitereführende Informationen und Produkte finden Sie auch direkt auf der <a href="https://www.weidemann.de/de/">Weidemannhomepage</a>.
      </p>
    </div>
  </div>
</div>

<div class = "mb-4"></div>


<div class="card bg-dark text-white">
  <img class="card-img" src="../../assets/img/img.component.homepage.jpg" alt="Card image">
</div>
  <div class = "mb-4"></div>


  <div class="card-deck">
    <div class="card">
     <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
      <div class="card-body">
        <h5 class="card-title">Kompetente und ehrliche Beratung</h5>
        <p class="card-text">
          Bei uns bekommen Sie eine maßgeschneiderte Beratung auf Ihren Betrieb und Ihre Bedürfnisse ausgerichtet.
          Egal ob Hoftrac, Teleskoplader, Teleskopradlader, Radlader oder Extras - wir finden für Sie alles was Sie brauchen ~ und das zu einem fairen Preis!
        </p>
      </div>
    </div>

    <div class="card">
      <img class="card-img-top" src="../../assets/img/img.component.hoftrac.jpg" alt="Hoftrac">
      <div class="card-body">
        <h5 class="card-title">Hoftrac</h5>
        <p class="card-text">Kompakte Bauweise, niedriger Schwerpunkt, geringer Wenderadius und kraftvolle Leistung – diese Merkmale kennzeichnen seit Jahren die Weidemann Hoftracs.</p>
        <a href="/hoftrac" class="card-link">Zu den Hoftracs</a>
      </div>
    </div>
    <div class="card">
      <img class="card-img-top" src="../../assets/img/img.component.radlader.png" alt="Radlader">
      <div class="card-body">
        <h5 class="card-title">Radlader</h5>
        <p class="card-text">
          Hoher Funktionalität, großer Arbeitskomfort und hervorragenden Sicherheitsstandards mit jeder Menge Kraft und Ausdauer - das sind die Weidemann Radlader.
        </p>
        <a href="/radlader" class="card-link">Zu den Radladern</a>
      </div>
    </div>
  </div>

<!-- zweite ebene -->
  <div class = "mb-4"></div>

  <div class="card-deck">
    <div class="card">
      <img class="card-img-top" src="../../assets/img/img.component.extras.png" alt="Extras">
      <div class="card-body">
        <h5 class="card-title">Extras und Zubehör</h5>
        <p class="card-text">
          Ob Anbauwerkzeuge oder Bereifung - wir finden das passende direkt für Sie.
             </p>
        <a href="/extras" class="card-link">Zum Zubehör</a>

      </div>
    </div>

    <div class="card">
      <img class="card-img-top" src="../../assets/img/img.component.teleskoplader.png" alt="Teleskoplader">
      <div class="card-body">
        <h5 class="card-title">Teleskoplader</h5>
        <p class="card-text">Einzigartig in ihrer Kombination aus Hubhöhe, Breite und Maschinenleistung.
          Mit Allradantrieb und Allradlenkung sind Weidemann Teleskoplader absolut standsicher und gut gerüstet für unterschiedlichste Aufgaben.</p>
        <a href="/teleskoplader" class="card-link">Zu den Teleskopladern</a>

      </div>
    </div>
    <div class="card">
      <img class="card-img-top" src="../../assets/img/img.component.teleskopradlader.png" alt="Teleskopradlader">
      <div class="card-body">
        <h5 class="card-title">Teleskopradlader</h5>
        <p class="card-text">
          Hier treffen die Grundtugenden wie Wendigkeit, Standsicherheit und Hubkraft, welche einen knickgelenkten Radlader auszeichnen, auch auf einen Teleskopradlader zu – dies macht die Teleskopradlader so erfolgreich.
        </p>
        <a href="/teleskopradlader" class="card-link">Zu den Teleskopradladern</a>
      </div>
    </div>
  </div>

<div class = "mb-4"></div>

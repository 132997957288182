<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
    <h1>Die kompakten Teleskoplader</h1>
  </div>
</div>
<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
    <i>
      <h2> Einzigartige Kombination aus Hubhöhe, Breite und Maschinenleistung.  <br></h2></i><br>
    Mit Allradantrieb und Allradlenkung sind sie absolut standsicher und gut gerüstet für die vielen unterschiedlichen Arbeitsaufgaben. Wer einen Weidemann Teleskoplader fährt, gibt ihn einfach nicht mehr her.  </div>
</div>

<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <h2 class="card-title">T4512</h2>
    <img class="card-img-top" src="../../assets/img/teleskoplader/img.component.teleskoplader1.png" alt="Teleskoplader">
    <img class="card-img-top" src="../../assets/img/teleskoplader/img.component.teleskoplader2.png" alt="Teleskoplader">
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">T4512</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">(Max.) Motorleistung</th>
          <td>18,4 (25) kW</td>
        </tr>
        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>2750-2900 kg </td>
        </tr>
        <tr>
          <th scope="row">Nutzlast (max.)</th>
          <td>1.250 kg</td>
        </tr>
        <tr>
          <th scope="row">Gesamtbreite</th>
          <td> 1.564 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/teleskoplader/model/t4512-1/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/teleskoplader/model/t4512-1/">mehr Informationen zum T4512</a>
    </div>
  </div>
</div>


<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <h2 class="card-title">T5522</h2>
    <img class="card-img-top" src="../../assets/img/teleskoplader/img.component.teleskoplader3.png" alt="Teleskoplader" >
    <img class="card-img-top" src="../../assets/img/teleskoplader/img.component.teleskoplader4.png" alt="Teleskoplader" >
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">T5522</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">(Max.) Motorleistung</th>
          <td>45 (61)  kW</td>
        </tr>
        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>4.200  kg </td>
        </tr>
        <tr>
          <th scope="row">Nutzlast (max.)</th>
          <td>2.200 kg</td>
        </tr>
        <tr>
          <th scope="row">Gesamtbreite</th>
          <td>1.808 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/teleskoplader/model/t5522/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/teleskoplader/model/t5522/">mehr Informationen zum T5522</a>
    </div>
  </div>
</div>

<div class = "mb-4"></div>


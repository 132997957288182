<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
    <h1>Die multifunktionalen Hoftracs</h1>
      </div>
</div>
<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
  <i>
  <h2>Kompakte Bauweise, niedriger Schwerpunkt, geringer Wenderadius & kraftvolle Leistung<br></h2></i><br>
  diese Merkmale kennzeichnen seit Jahren die Weidemann Hoftrac bzw. Hoflader Baureihen und bestimmen nach wie vor den großen Erfolg der Maschinen.
    Mühelos erfüllen die Hoftracs bzw. Hoflader jede Arbeitsaufgabe und arbeiten dabei schnell, flexibel und sicher – ein Hoftrac bzw. Hoflader ist einfach jederzeit einsatzbereit.

  </div>
</div>


<div class = "mb-4"></div>

  <div class="card-deck">
      <div class="card">
        <h2 class="card-title">Basic Line 1140</h2>
        <img class="card-img-top" src="../../assets/img/lader/img.component.lader1.png" alt="Hoftrac" >
        <img class="card-img-top" src="../../assets/img/lader/img.component.lader2.png" alt="Hoftrac2" >
      </div>
      <div class="card col-sm-5">
        <div class="card-body ">
          <table class="table table-striped">
            <thead>
            <tr>
              <th style="color: #0c5460">1140 Basic Line</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row">(Max.) Motorleistung</th>
              <td>18.4 (25)  kW</td>
            </tr>
            <tr>
              <th scope="row">Betriebsgewicht</th>
              <td>1630 kg</td>
            </tr>
            <tr>
              <th scope="row">Kipplast mit Schaufel/Maschine gerade</th>
              <td>664-733 kg</td>
            </tr>
            <tr>
              <th scope="row">Gesamtbreite</th>
              <td>850 mm</td>
            </tr>
            </tbody>
          </table>
          <a href="https://www.weidemann.de/de/hoftrac/model/1140-basic-line/type/TechnicalData/">weitere technische Daten</a><br><br>
          <a href="https://www.weidemann.de/de/hoftrac/model/1140-basic-line/">mehr Informationen zum 1140 Basic Line</a>

        </div>
      </div>
  </div>

<!-- zweite ebene -->
<div class = "mb-4"></div>


<div class="card-deck">
  <div class="card">
    <h2 class="card-title">1140</h2>
    <img class="card-img-top" src="../../assets/img/lader/img.component.lader3.png" alt="Hoftrac" >
    <img class="card-img-top" src="../../assets/img/lader/img.component.lader4.png" alt="Hoftrac2" >
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">1140</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">(Max.) Motorleistung</th>
          <td>18.4 (25)  kW</td>
        </tr>
        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>1630 kg</td>
        </tr>
        <tr>
          <th scope="row">Kipplast mit Schaufel/Maschine gerade</th>
          <td>664-733 kg</td>
        </tr>
        <tr>
          <th scope="row">Gesamtbreite</th>
          <td>850 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/hoftrac/model/1140/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/hoftrac/model/1140/">mehr Informationen zum 1140</a>

    </div>
  </div>
</div>

<!-- e serie -->

<div class = "mb-4"></div>


<div class="card-deck">
  <div class="card">
    <h2 class="card-title">1160 eHoftrac</h2>
    <p>
    <b> Der neue 1160 eHoftrac – Die Innovation für Ihren Betrieb! </b> <br>

      CO2-Abgase, Lärm und Rußpartikel in Gebäuden – das war gestern! Der neue 1160 eHoftrac verbannt Emissionen aus Ihrem Arbeitsalltag. Er verbindet gewohnte Nutzungseigenschaften und die bewährte Performance unseres Hoftracs® mit modernster Technologie und einem umweltbewussten und kosteneffizienten Elektroantrieb.
    </p>
    <img class="card-img-top" src="../../assets/img/lader/img.component.lader4E.png" alt="Hoftrac" >
    <img class="card-img-top" src="../../assets/img/lader/img.component.lader4E2.png" alt="Hoftrac2" >
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">1160 eHoftrac</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">Fahrmotor, Leistung S2 (60 min)</th>
          <td>6,5 kW</td>
        </tr>
        <tr>
          <th scope="row">Hubmotor, Leistung S3 (15 %)</th>
          <td>9 kW</td>
        </tr>

        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>2350 kg</td>
        </tr>

        <tr>
          <th scope="row">Gesamtbreite</th>
          <td> 1.044 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/hoftrac/model/1160-ehoftrac/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/hoftrac/model/1160-ehoftrac/">mehr Informationen zum 1160 eHoftrac</a>

    </div>
  </div>
</div>

<div class = "mb-4"></div>





<!--<owl-carousel [options]="hoftracSlider" [carouselClasses]="['owl-theme', 'customClassName']" ]>
  <div class="item">
    <img src="../../assets/img/img.component.hoftrac.jpg">
  </div>
</owl-carousel>
-->


<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
    <h1>Die kraftvollen Radlader </h1>
  </div>
</div>
<div class = "mb-4"></div>

<div class="card">
  <div class="card-body">
    <i>
      <h2> Die Weidemann Radlader machen jeden Arbeitseinsatz noch produktiver. <br></h2></i><br>
    Denn neben hoher Funktionalität, großem Arbeitskomfort und hervorragenden Sicherheitsstandards besitzen sie jede Menge Kraft und Ausdauer - Weidemann bietet Ihnen für jede Aufgabe die passende Lösung.
  </div>
</div>


<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <h2 class="card-title">2080LP</h2>
    <img class="card-img-top" src="../../assets/img/radlader/img.component.radlader1.png" alt="Radlader" >
    <img class="card-img-top" src="../../assets/img/radlader/img.component.radlader2.png" alt="Radlader">
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">2080LP</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">(Max.) Motorleistung</th>
          <td>45 (61) kW</td>
        </tr>
        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>3900 kg</td>
        </tr>
        <tr>
          <th scope="row">Kipplast mit Schaufel/Maschine gerade</th>
          <td>2898 - 3028 kg </td>
        </tr>
        <tr>
          <th scope="row">Gesamtbreite</th>
          <td>1.415 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/radlader/model/2080lp/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/radlader/model/2080lp/">mehr Informationen zum 2080LP</a>

    </div>
  </div>
</div>

<div class = "mb-4"></div>

<div class="card-deck">
  <div class="card">
    <h2 class="card-title">3080</h2>
    <img class="card-img-top" src="../../assets/img/radlader/img.component.radlader1.png" alt="Radlader">
    <img class="card-img-top" src="../../assets/img/radlader/img.component.radlader2.png" alt="Radlader">
  </div>
  <div class="card col-sm-5">
    <div class="card-body ">
      <table class="table table-striped">
        <thead>
        <tr>
          <th style="color: #0c5460">3080</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">(Max.) Motorleistung</th>
          <td>55,4 (75) kW</td>
        </tr>
        <tr>
          <th scope="row">Betriebsgewicht</th>
          <td>5100 kg</td>
        </tr>
        <tr>
          <th scope="row">Kipplast mit Schaufel/Maschine gerade</th>
          <td>2898 - 3028 kg </td>
        </tr>
        <tr>
          <th scope="row">Gesamtbreite</th>
          <td>1.580 mm</td>
        </tr>
        </tbody>
      </table>
      <a href="https://www.weidemann.de/de/radlader/model/2080lp/type/TechnicalData/">weitere technische Daten</a><br><br>
      <a href="https://www.weidemann.de/de/radlader/model/3080/">mehr Informationen zum 3080</a>
    </div>
  </div>
</div>

<div class = "mb-4"></div>

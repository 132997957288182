import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { HoftracComponent } from './hoftrac/hoftrac.component';
import { RadladerComponent } from './radlader/radlader.component';
import { TeleskopradladerComponent } from './teleskopradlader/teleskopradlader.component';
import { TelekopladerComponent } from './telekoplader/telekoplader.component';
import { ExtrasComponent } from './extras/extras.component';
import { OwlModule} from "ngx-owl-carousel";

import { ScullyLibModule } from '@scullyio/ng-lib';

import {ReactiveFormsModule} from "@angular/forms";
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import { HeaderComponent } from './navigation/header/header.component';

import { MatButtonModule } from '@angular/material/button';
import { RoutingModule } from './routing/routing.module';
import {MatMenuModule} from "@angular/material/menu";

import {LayoutModule} from "@angular/cdk/layout";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    KontaktComponent,
    HoftracComponent,
    RadladerComponent,
    TeleskopradladerComponent,
    TelekopladerComponent,
    ExtrasComponent,
    SidenavListComponent,
    HeaderComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ScullyLibModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    RoutingModule,
    MatMenuModule,

    LayoutModule
  ],
  exports:[
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    LayoutModule,
    HeaderComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}

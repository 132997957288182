<mat-toolbar color="primary">
  <div fxHide.gt-xs >
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div>
    <a routerLink="/" class="logo">Aulatec</a>
  </div>
  <nav class="container">
    <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
      <ul fxLayout fxLayoutGap="15px" class="navigation-items">
        <li>
          <a routerLink="/hoftrac">Hoftrac</a>
        </li>
        <li>
          <a routerLink="/radlader">Radlader</a>
        </li>
        <li>
          <a routerLink="/teleskoplader">Teleskoplader</a>
        </li>
        <li>
          <a routerLink="/teleskopradlader">Teleskopradlader</a>
        </li>
        <li>
          <a routerLink="/extras">Extras</a>
        </li>
        <li>
        <a routerLink="/kontakt">Kontakt</a>
      </li>

      </ul>
    </div>
  </nav>
</mat-toolbar>











import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teleskopradlader',
  templateUrl: './teleskopradlader.component.html',
  styleUrls: ['./teleskopradlader.component.scss']
})
export class TeleskopradladerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//neue Components hier einbinden:
import { HomeComponent } from './home/home.component'; // Add this
import { KontaktComponent } from './kontakt/kontakt.component';
import {HoftracComponent} from "./hoftrac/hoftrac.component";
import {RadladerComponent} from "./radlader/radlader.component";
import {TeleskopradladerComponent} from "./teleskopradlader/teleskopradlader.component";
import {ExtrasComponent} from "./extras/extras.component"; // Add this
import {TelekopladerComponent} from "./telekoplader/telekoplader.component";

import {HeaderComponent} from "./navigation/header/header.component";
import {SidenavListComponent} from "./navigation/sidenav-list/sidenav-list.component";

import { MatListModule } from '@angular/material/list';


//und hier nochmal aufführen:
const routes: Routes = [
  { path: '', component: HomeComponent },              // Add this
  { path: 'hoftrac', component: HoftracComponent },        // Add this
  { path: 'radlader', component: RadladerComponent }   ,
  { path: 'teleskoplader', component: TelekopladerComponent },
  { path: 'teleskopradlader', component: TeleskopradladerComponent } ,
  { path: 'extras', component: ExtrasComponent } ,
  { path: 'kontakt', component: KontaktComponent },


  { path: 'sideNav', component: SidenavListComponent },
  { path: 'header', component: HeaderComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
